import {FilterEqOperation, FilterType, IFilterDTO, IFilterOption} from '../types/galleryTypes';
import {CollectionFilterModel} from '../models/CollectionFilterModel';
import {CustomCollectionFilterModel} from '../models/CustomCollectionFilterModel';
import _ from 'lodash';
import {FilterConfigsService} from './FilterConfigsService';

export class CollectionFiltersService {
  private readonly collectionFilterModels: (CustomCollectionFilterModel | CollectionFilterModel)[] = [];

  constructor(private readonly mainCollectionId: string, private readonly filterConfigsService: FilterConfigsService) {}

  public createCollectionFilterModel(
    filterType: FilterType.COLLECTION,
    filterOptions: IFilterOption[]
  ): CollectionFilterModel {
    const enabledFilterOptions = this.getEnabledCollectionFilterOptions(filterOptions);
    const title = this.filterConfigsService.getFilterConfigTitle(filterType);
    const collectionFilterModel = new CollectionFilterModel(
      filterType,
      title,
      enabledFilterOptions,
      this.mainCollectionId
    );
    this.collectionFilterModels.push(collectionFilterModel);

    return collectionFilterModel;
  }

  public createCustomCollectionFilterModels(
    filterType: FilterType.CUSTOM_COLLECTION,
    filterOptions: IFilterOption[]
  ): CustomCollectionFilterModel[] {
    const customCollectionFilterConfigs = this.filterConfigsService.getCustomCollectionFilterConfigs();

    const customCollectionFilterModels = customCollectionFilterConfigs.map(filterConfig => {
      const enabledFilterOptions = this.filterConfigsService.getEnabledFilterOptionsFromFilterConfig(
        filterOptions,
        filterConfig
      );
      return new CustomCollectionFilterModel(filterType, filterConfig.filterTitle, enabledFilterOptions);
    });

    this.collectionFilterModels.push(...customCollectionFilterModels);

    return customCollectionFilterModels;
  }

  public toDTO(): IFilterDTO {
    let collectionIds = [];
    let collectionFilterDTO: IFilterDTO;

    this.collectionFilterModels.forEach(filterModel => {
      collectionIds = collectionIds.concat(filterModel.activeOptions);
    });

    collectionIds = _.uniq(_.pull(collectionIds, this.mainCollectionId));

    if (collectionIds.length) {
      collectionFilterDTO = {
        field: 'categories',
        op: FilterEqOperation.IN_ALL,
        values: collectionIds,
      };
    }

    return collectionFilterDTO;
  }

  public getEnabledCollectionFilterOptions(filterOptions: IFilterOption[]): IFilterOption[] {
    let result = _.cloneDeep(filterOptions);
    const collectionFilterConfigs = this.filterConfigsService.getCollectionFilterConfigs();

    if (collectionFilterConfigs.length !== 0) {
      result = this.filterConfigsService.getEnabledFilterOptionsFromFilterConfig(
        filterOptions,
        collectionFilterConfigs[0]
      );
    }

    return result;
  }
}
