import {
  FilterModel,
  IFilterSelectionValue,
  IGalleryControllerConfig,
  IGalleryStyleParams,
  IProduct,
  IPropsInjectedByViewerScript,
  ISorting,
  ISortingOption,
  ITextsMap,
  ProductIdToProductPageUrlMap,
} from '../types/galleryTypes';
import {
  DEFAULT_COLLECTION_ID,
  Experiments,
  sortingOptions,
  TRACK_EVENT_COLLECTION,
  translationPath,
} from '../constants';
import {ProductsService} from '../services/ProductsService';
import {SiteStore} from '@wix/wixstores-client-core/dist/es/src/viewer-script/site-store/siteStore';
import {getTranslations, isWorker} from '@wix/wixstores-client-core/dist/es/src/viewer-script/utils';
import {FiltersService} from './FiltersService';
import {getStyleParamsWithDefaults} from '@wix/wixstores-client-common-components/dist/src/outOfIframes/defaultStyleParams/getStyleParamsWithDefaults';
import {FilterConfigsService} from '../services/FilterConfigsService';
import {APP_DEFINITION_ID, PageMap} from '@wix/wixstores-client-core/dist/es/src/constants';
import {getStyleParamsWithDefaultsFunc} from '../getStyleParamsWithDefaultsFunc';
import {IControllerConfig, IWidgetControllerConfig} from '@wix/native-components-infra/dist/es/src/types/types';
import {MultilingualService} from '@wix/wixstores-client-core/dist/src/multilingualService/multilingualService';
import _ from 'lodash';
import {SortService} from '../services/SortService';
import {QueryParamsService} from '../services/QueryParamsService';

export class GalleryStore {
  private readonly fedopsLogger;
  private mainCollectionId: string;
  private filtersService: FiltersService;
  private multilingualService: MultilingualService;
  private productPageSectionUrl: string;
  private translations;
  private filterConfigsService: FilterConfigsService;
  private isFedopsReport: boolean = true;
  private productIdToProductPageUrlMap: ProductIdToProductPageUrlMap;
  private readonly publicData: IControllerConfig['publicData'];
  private readonly sortService: SortService;
  private queryParamsService: QueryParamsService;

  constructor(
    public styleParams: IGalleryStyleParams,
    private readonly config: IWidgetControllerConfig['config'],
    private readonly updateComponent: (props: Partial<IPropsInjectedByViewerScript>) => void,
    private readonly siteStore: SiteStore,
    private readonly productsService: ProductsService,
    private readonly compId: string,
    private readonly type: string,
    private readonly reportError: (e) => any
  ) {
    const fedopsLoggerFactory = this.siteStore.platformServices.fedOpsLoggerFactory;
    this.fedopsLogger = fedopsLoggerFactory.getLoggerForWidget({
      appId: APP_DEFINITION_ID,
      widgetId: this.type,
    });

    if (isWorker()) {
      this.fedopsLogger.appLoadStarted();
    }
    //todo: COMPONENT === null is not tested, be this check can be removed after bolt will stop sending nulls https://wix.slack.com/archives/CAKBA7TDH/p1555852184003900
    if (this.config.publicData.COMPONENT === null || this.config.publicData.COMPONENT === undefined) {
      this.config.publicData.COMPONENT = {};
    }
    this.publicData = _.cloneDeep(this.config.publicData);
    this.sortService = new SortService();
  }

  public async setInitialState(): Promise<any> {
    let data, translations, url, sorting: ISortingOption, products, limit;

    if (this.siteStore.experiments.enabled(Experiments.FilterAndSortWithQueryParams)) {
      this.queryParamsService = new QueryParamsService(this.siteStore.location);
      if (this.siteStore.location.query.sort) {
        sorting = this.getSortFromQueryParam();
      }
      if (this.siteStore.location.query.page) {
        limit = this.getProductsLimitByPageFromQueryParam(this.productsService.getProductPerPage());
      }

      [translations, data, {url}] = await Promise.all([
        getTranslations(translationPath(this.siteStore.baseUrls.galleryBaseUrl, this.siteStore.locale)),
        this.productsService.getInitialData({
          externalId: this.config.externalId,
          compId: this.compId,
          sort: this.sortService.getSortDTO(sorting),
          filters: null,
          limit,
          offset: 0,
        }),
        this.siteStore.getSectionUrl(PageMap.PRODUCT),
      ]).catch(this.reportError);

      sorting && this.sortService.setSelectedSort(sorting.id);
    } else {
      [translations, data, {url}] = await Promise.all([
        getTranslations(translationPath(this.siteStore.baseUrls.galleryBaseUrl, this.siteStore.locale)),
        this.productsService.oldGetInitialData({
          externalId: this.config.externalId,
          compId: this.compId,
        }),
        this.siteStore.getSectionUrl(PageMap.PRODUCT),
      ]);
    }

    products = data.catalog.category.productsWithMetaData.list;
    this.translations = translations;
    this.productPageSectionUrl = url;
    this.mainCollectionId = this.productsService.getMainCollectionId();
    this.productIdToProductPageUrlMap = this.generateProductIdToProductPageUrlMap(products);

    this.multilingualService = new MultilingualService(
      this.publicData.COMPONENT,
      data.appSettings.widgetSettings,
      this.siteStore.getMultiLangFields(),
      this.siteStore.locale
    );

    this.filterConfigsService = new FilterConfigsService(
      data.appSettings.widgetSettings.FILTERS,
      this.publicData.COMPONENT.FILTERS && this.publicData.COMPONENT.FILTERS.data,
      this.styleParams.booleans,
      this.multilingualService,
      translations
    );

    this.filtersService = new FiltersService(this.siteStore, this.mainCollectionId, this.filterConfigsService);
    let filterModels: FilterModel[] | [] = [];
    if (this.shouldShowFilters()) {
      filterModels = await this.fetchFilters();
      if (this.siteStore.experiments.enabled(Experiments.FilterAndSortWithQueryParams)) {
        filterModels = this.getFiltersFromQueryParams(filterModels);
        data = await this.productsService.getInitialData({
          externalId: this.config.externalId,
          compId: this.compId,
          sort: this.sortService.getSortDTO(sorting),
          filters: this.filtersService.getNewFilterDTO(),
          limit,
          offset: 0,
        });
        products = data.catalog.category.productsWithMetaData.list;
      }
    }

    if (this.siteStore.experiments.enabled(Experiments.FilterAndSortWithQueryParams)) {
      this.updateComponent({...this.getPropsToInject(products, filterModels), isFirstPage: false});
    } else {
      this.updateComponent(this.getPropsToInject(products, filterModels));
    }

    if (this.siteStore.isSSR()) {
      this.fedopsLogger.appLoaded();
    }
  }

  public onAppLoaded(): void {
    /* istanbul ignore next: hard to test it */
    if (this.isFedopsReport) {
      this.fedopsLogger.appLoaded();
      this.isFedopsReport = false;

      //tslint:disable-next-line:no-floating-promises
      this.siteStore.biLogger.exposureEventForTests({
        testName: 'Gallery loaded',
        is_eligible: this.shouldShowFilters(),
        isMobileFriendly: this.siteStore.isMobileFriendly,
      });
    }
  }

  private getPropsToInject(products: IProduct[], filterModels: FilterModel[] | []): IPropsInjectedByViewerScript {
    return {
      clearFilters: this.clearFilters.bind(this),
      cssBaseUrl: this.siteStore.baseUrls.galleryBaseUrl,
      filterModels,
      filterProducts: this.filterProducts.bind(this),
      filterProductsOnMobile: this.filterProductsOnMobile.bind(this),
      handleProductItemClick: this.handleProductItemClick.bind(this),
      hasMoreProductsToLoad: this.productsService.hasMoreProductsToLoad(),
      hasSelectedFilters: false,
      isFirstPage: true,
      isInteractive: this.siteStore.isInteractive(),
      isLiveSiteMode: this.siteStore.isSiteMode(),
      isLoaded: true,
      isMobile: this.siteStore.isMobile(),
      isRTL: this.siteStore.isRTL(),
      loadMoreProducts: this.loadMoreProducts.bind(this),
      loading: false,
      showShowLightEmptyState: this.productsService.hideGallery,
      mainCollectionId: this.mainCollectionId,
      onAppLoaded: this.onAppLoaded.bind(this),
      openQuickView: this.openQuickView.bind(this),
      productIdToProductPageUrlMap: this.productIdToProductPageUrlMap,
      products,
      ravenUserContextOverrides: {id: this.siteStore.storeId},
      setProductsPerPage: this.setProductsPerPage.bind(this),
      shouldShowClearFilters: this.siteStore.isMobile(),
      shouldShowMobileFiltersModal: false,
      shouldShowSort: this.shouldShowSort(),
      sortProducts: this.sortProducts.bind(this),
      textsMap: this.getTextsMap(),
      toggleFiltersModalVisibility: this.toggleFiltersModalVisibility.bind(this),
      totalProducts: this.productsService.totalCount,
      experiments: {
        isMobileSortEnabled: this.siteStore.experiments.enabled(Experiments.MobileSort),
        isFilterAndSortWithQueryParams: this.siteStore.experiments.enabled(Experiments.FilterAndSortWithQueryParams),
        isResponsiveGallery: this.siteStore.experiments.enabled(Experiments.ResponsiveGallery),
      },
      numberOfSelectedFilterTypes: 0,
      sortingOptions: this.getSortingOptions(),
      handleMobileSortClick: this.handleMobileSortClick.bind(this),
      selectedSort: this.sortService.getSelectedSort(),
    };
  }

  private getTextsMap(): ITextsMap {
    return {
      ...this.translations,
      mobileFiltersButtonText: this.translations['gallery.mobile.filters.title.button'],
      quickViewButtonText: this.translations.QUICK_VIEW,
      filtersTitleText: this.multilingualService.get('FILTERS_MAIN_TITLE') || this.translations['filter.MAIN_TITLE'],
      loadMoreButtonText: this.multilingualService.get('LOAD_MORE_BUTTON') || this.translations.LOAD_MORE_BUTTON,
      clearFiltersButtonText: this.translations['filter.CLEAN_ALL'],
      allCollectionsFilterButtonText: this.translations['filter.CATEGORY_ALL'],
      noProductsMessageText: this.translations.NO_PRODUCTS_MESSAGE_MAIN,
      noProductsFilteredMessageText: this.translations.NO_PRODUCTS_FILTERED_MESSAGE_MAIN,
      sortTitleText: this.multilingualService.get('SORTING_MAIN_TITLE') || this.translations.SORT_BY,
      sortOptionNewestText: this.translations['sort.NEWEST'],
      sortOptionLowPriceText: this.translations['sort.PRICE_LOW'],
      sortOptionHighPriceText: this.translations['sort.PRICE_HIGH'],
      sortOptionNameAZText: this.translations['sort.NAME_AZ'],
      sortOptionNameZAText: this.translations['sort.NAME_ZA'],
      productPriceBeforeDiscountSR: this.translations['sr.PRODUCT_PRICE_BEFORE_DISCOUNT'],
      productPriceAfterDiscountSR: this.translations['sr.PRODUCT_PRICE_AFTER_DISCOUNT'],
      productPriceWhenThereIsNoDiscountSR: this.translations['sr.PRODUCT_PRICE_WHEN_THERE_IS_NO_DISCOUNT'],
      productOutOfStockText: this.translations.OUT_OF_STOCK_LABEL,
      galleryRegionSR: this.translations['sr.region.GALLERY'],
      filtersSubmitButtonText: this.translations['gallery.mobile.filters.buttonApply'],
      digitalProductBadgeAriaLabelText: this.translations['sr.digitalProduct'],
    };
  }

  private generateProductIdToProductPageUrlMap(products: IProduct[]): ProductIdToProductPageUrlMap {
    return products.reduce(this._productsToUrlsReducer.bind(this), {});
  }

  private _productsToUrlsReducer(acc, product): {[productId: string]: string} {
    acc[product.id] = `${this.productPageSectionUrl}/${product.urlPart}`;
    return acc;
  }

  private async filterProducts(filterId: number, selectionValue: IFilterSelectionValue, fetchProducts: boolean = true) {
    this.filtersService.updateActiveFilterOption(filterId, selectionValue);
    if (this.siteStore.experiments.enabled(Experiments.FilterAndSortWithQueryParams)) {
      this.queryParamsService.updateQueryParamsByFilters(
        filterId,
        this.filtersService.getFilterModels(),
        this.mainCollectionId
      );
    }
    if (!fetchProducts || this.siteStore.isMobile()) {
      return this.updateComponent({
        filterModels: this.filtersService.getFilterModels(),
        hasSelectedFilters: this.hasSelectedFilters(),
      });
    }

    return this.updateComponentWithFilteredProducts();
  }

  private async filterProductsOnMobile() {
    //tslint:disable-next-line:no-floating-promises
    this.siteStore.biLogger.galleryClickApplyFilter({
      filterTypes: this.filtersService.getSelectedFilterTypes().toString(),
    });

    this.filtersService.updateSnapshotWithActiveOptions();
    this.updateComponent({
      loading: true,
      numberOfSelectedFilterTypes: this.filtersService.getSelectedFilterTypes().length,
    });

    await this.updateComponentWithFilteredProducts();
    this.updateComponent({loading: false, shouldShowMobileFiltersModal: false});
  }

  private async clearFilters() {
    this.filtersService.resetFilters();

    //tslint:disable-next-line:no-floating-promises
    this.siteStore.biLogger.galleryClickClearAllFilters({});

    if (this.siteStore.isMobile()) {
      this.updateComponent({
        filterModels: this.filtersService.getFilterModels(),
        hasSelectedFilters: this.hasSelectedFilters(),
      });
    } else {
      await this.updateComponentWithFilteredProducts();
    }
  }

  private toggleFiltersModalVisibility(show: boolean) {
    if (show) {
      this.filtersService.updateSnapshotWithActiveOptions();
      //tslint:disable-next-line:no-floating-promises
      this.siteStore.biLogger.galleryClickFilter({});
    } else {
      this.filtersService.updateActiveOptionsWithSnapshot();
    }

    this.updateComponent({
      shouldShowMobileFiltersModal: show,
      filterModels: this.filtersService.getFilterModels(),
    });
  }

  private async updateComponentWithFilteredProducts() {
    const filtersDTO = this.filtersService.getNewFilterDTO();
    const newProducts = await this.productsService.filterProducts(
      filtersDTO,
      this.filtersService.getCollectionIdsFilterDTO()
    );
    this.updateComponent({
      filterModels: this.filtersService.getFilterModels(),
      hasMoreProductsToLoad: this.productsService.hasMoreProductsToLoad(),
      hasSelectedFilters: this.hasSelectedFilters(),
      isFirstPage: false,
      productIdToProductPageUrlMap: this.generateProductIdToProductPageUrlMap(newProducts),
      products: newProducts,
      shouldShowClearFilters: this.shouldShowClearFilters(),
      shouldShowSort: this.shouldShowSort(),
    });
  }

  private setProductsPerPage(productPerPage: number) {
    this.productsService.setProductsPerPage(productPerPage);
  }

  private shouldShowClearFilters(): boolean {
    return this.filtersService.shouldShowClearFilters() || this.siteStore.isMobile();
  }

  private hasSelectedFilters(): boolean {
    return this.filtersService.shouldShowClearFilters();
  }

  private async loadMoreProducts(visibleProducts: number) {
    this.fedopsLogger.interactionStarted('load-more');
    const collectionId = this.productsService.getMainCollectionId();
    //tslint:disable-next-line:no-floating-promises
    this.siteStore.biLogger.clickLoadMoreInGallerySf(
      collectionId === DEFAULT_COLLECTION_ID ? {} : {categoryId: collectionId}
    );
    if (this.siteStore.experiments.enabled(Experiments.FilterAndSortWithQueryParams)) {
      this.queryParamsService.updateQueryParamsByPage(visibleProducts, this.productsService.getProductPerPage());
    }
    const newProducts = await this.productsService.loadMoreProducts(visibleProducts);
    if (newProducts === null) {
      this.updateComponent({
        hasMoreProductsToLoad: false,
      });
      return;
    }
    this.updateComponent({
      products: newProducts,
      focusedProductIndex: visibleProducts,
      isFirstPage: false,
      productIdToProductPageUrlMap: this.generateProductIdToProductPageUrlMap(newProducts),
      hasMoreProductsToLoad: this.productsService.hasMoreProductsToLoad(),
    });

    this.fedopsLogger.interactionEnded('load-more');
  }

  private readonly getSortFromQueryParam = (): ISortingOption => {
    return this.sortService.getSort(this.queryParamsService.getQueryParam('sort'));
  };

  private readonly getProductsLimitByPageFromQueryParam = (productPerPage: number): number => {
    return parseInt(this.queryParamsService.getQueryParam('page'), 10) * productPerPage;
  };

  private readonly getFiltersFromQueryParams = (filterModels: FilterModel[]) => {
    const queryParamsFilters = this.queryParamsService.getFiltersFromQueryParams(filterModels);
    if (queryParamsFilters) {
      this.filtersService.updateActiveFilterOptionsByQueryParams(queryParamsFilters);
    }
    return this.filtersService.getFilterModels();
  };

  private async sortProducts(sorting: ISorting) {
    if (this.siteStore.experiments.enabled(Experiments.FilterAndSortWithQueryParams)) {
      this.queryParamsService.updateQueryParamsBySort(sorting);
    }

    const selectedSort = this.sortService.setSelectedSort(sorting.id);
    //tslint:disable-next-line:no-floating-promises
    this.siteStore.biLogger.sortGallerySf({sortDir: sorting.direction, method: sorting.id.split('_')[0]});
    const newProducts = await this.productsService.sortProducts(sorting.field ? sorting : null);
    this.updateComponent({
      products: newProducts,
      isFirstPage: false,
      productIdToProductPageUrlMap: this.generateProductIdToProductPageUrlMap(newProducts),
      hasMoreProductsToLoad: this.productsService.hasMoreProductsToLoad(),
      selectedSort,
    });
  }

  private shouldShowSort() {
    const {
      galleryShowSort,
      gallerySortNewest,
      gallerySortPriceAsc,
      gallerySortPriceDes,
      gallerySortNameAsc,
      gallerySortNameDes,
    } = this.styleParams.booleans;
    return (
      galleryShowSort &&
      (gallerySortNewest || gallerySortPriceAsc || gallerySortPriceDes || gallerySortNameAsc || gallerySortNameDes) &&
      this.productsService.products.length > 0
    );
  }

  private openQuickView({productId, index}: {productId: string; index: number}) {
    const product = this.productsService.getProduct(productId);
    // tslint:disable-next-line no-floating-promises
    this.siteStore.biLogger.clickedOnProductQuickViewSf({
      productId,
      hasRibbon: !!product.ribbon,
      hasOptions: (product as any).hasOptions,
      index,
    });
    // tslint:disable-line no-floating-promises
    this.productsService.quickViewProduct(productId, product.urlPart, this.compId, this.config.externalId); // tslint:disable-line no-floating-promises
    this.sendClickTrackEvent(product, index);
  }

  private sendClickTrackEvent(product: IProduct, index: number) {
    this.siteStore.windowApis.trackEvent('ClickProduct', {
      appDefId: APP_DEFINITION_ID,
      id: product.id,
      origin: 'Stores',
      name: product.name,
      list: 'Grid Gallery',
      category: TRACK_EVENT_COLLECTION,
      position: index,
      price: product.comparePrice || product.price,
      currency: this.siteStore.currency,
    });
  }

  private handleProductItemClick({
    biData: {productId, index},
  }: {
    biData: {
      productId: string;
      index: number;
    };
  }) {
    const product = this.productsService.getProduct(productId);

    this.productsService.storeNavigation(this.siteStore.siteApis.currentPage.id);

    // tslint:disable-next-line no-floating-promises
    this.siteStore.biLogger.clickOnProductBoxSf({
      productId,
      hasRibbon: !!product.ribbon,
      hasOptions: (product as any).hasOptions,
      index,
      productType: product.productType,
    });
    // tslint:disable-line no-floating-promises
    this.sendClickTrackEvent(product, index);

    this.siteStore.navigate({
      sectionId: PageMap.PRODUCT,
      state: product.urlPart,
      queryParams: undefined,
    }); // tslint:disable-line no-floating-promises
  }

  private fetchFilters(): Promise<FilterModel[]> {
    return this.filtersService.fetchFilters();
  }

  private shouldShowFilters(): boolean {
    return this.styleParams.booleans.galleryShowFilters && this.filterConfigsService.shouldShowFilters();
  }

  private getSortingOptions(): ISortingOption[] {
    return sortingOptions.filter(o => this.styleParams.booleans[o.settingsShouldDisplayKey] !== false);
  }

  private handleMobileSortClick() {
    //tslint:disable-next-line:no-floating-promises
    this.siteStore.biLogger.galleryClickSortBy({});
  }

  private updatePublicData(newPublicData: IGalleryControllerConfig['publicData']) {
    Object.keys(newPublicData.COMPONENT).forEach(key => {
      this.publicData.COMPONENT[key] = newPublicData.COMPONENT[key];
    });
  }

  public async updateState(
    newStyleParams: IGalleryStyleParams,
    newPublicData: IGalleryControllerConfig['publicData'] & {appSettings?: any}
  ): Promise<void> {
    this.updatePublicData(newPublicData);
    this.styleParams = getStyleParamsWithDefaults(newStyleParams, () =>
      getStyleParamsWithDefaultsFunc({
        style: {styleParams: newStyleParams},
        dimensions: undefined,
      })
    );

    if (newPublicData.appSettings) {
      this.multilingualService.setWidgetSettings(newPublicData.appSettings);
      if (newPublicData.appSettings.FILTERS) {
        this.filterConfigsService.setFilterConfigDTOs(newPublicData.appSettings.FILTERS);
        this.filtersService.deleteFilterModels();
      }
    }

    if (!this.shouldShowFilters()) {
      this.filtersService.deleteFilterModels();
      this.updateComponent({filterModels: []});
    } else if (this.filtersService.getFilterModels().length === 0) {
      const filterModels = await this.filtersService.fetchFilters();
      this.updateComponent({filterModels});
    }

    this.updateComponent({
      textsMap: this.getTextsMap(),
      shouldShowSort: this.shouldShowSort(),
      sortingOptions: this.getSortingOptions(),
    });
  }
}
